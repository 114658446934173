import { Component, Inject, ViewChild, Input, SimpleChanges } from '@angular/core';
import { DataBindingDirective } from '@progress/kendo-angular-grid';
import { ClientConfig, OnDemandProcessingDetails, Timecode, TriggerEventConfigDetails } from 'src/app/models/config.model';
import { JobName } from 'src/app/interfaces/jobName';
import { JobSetupService } from 'src/app/services/job-setup.service';
import { FormControl, FormGroup, Validators, FormBuilder, AbstractControl, ValidationErrors, AbstractControlOptions } from '@angular/forms';
import { MasterdataService } from 'src/app/services/masterdata.service';
import { Client } from 'src/app/interfaces/client';
import { OndemandprocessingService } from 'src/app/services/ondemandprocessing.service';
import { DialogService, DialogRef, DialogCloseResult } from '@progress/kendo-angular-dialog';
import { process } from "@progress/kendo-data-query";
import { text } from 'stream/consumers';
import { ExcelExportData } from '@progress/kendo-angular-excel-export';
import { DOCUMENT, Location } from '@angular/common';
import { Triggertype } from '../interfaces/triggertype';
import { TriggerEventService } from '../services/trigger-event.service';
import { UserAuthService } from '../auth/services/user-auth.service';
import { AuthService } from '@auth0/auth0-angular';
import { ClientService } from '../services/client.service';
import { SVGIcon, fileExcelIcon, filePdfIcon } from '@progress/kendo-svg-icons';


@Component({
  selector: 'app-ondemandprocessing',
  templateUrl: './ondemandprocessing.component.html',
  styleUrls: ['./ondemandprocessing.component.scss']
})
export class OndemandprocessingComponent {

  @Input()
  clientId: number = 0;
  public addDemand: boolean = false;
  @ViewChild(DataBindingDirective) dataBinding: DataBindingDirective | undefined;
  public ondemandApiData: OnDemandProcessingDetails[] = [];

  public onDemandGridView: OnDemandProcessingDetails[] = [];
  public selectedJobName: string | undefined;
  public selectedClientName: string | undefined;

  public clientData: Client[] = [];
  public client: Client[] = [];

  public jobNameSource: JobName[] = [];
  public jobName: JobName[] = [];

  public ondemand: any = {};
  public isNotificationRequiredForUnprocessedClaim = false;
  LoggedInName: string;
  public ismessageTextboxVisible: boolean = false;
  public openedmessage = false;
  public Title: string = "";
  public message: string;
  // public isStoppedProcessing = true;
  public isStoppedProcessingDisable: boolean = false;
  public status: string | undefined;
  public headerCustomClass;
  public selectedTimeCodes: any[] = [];
  public selectedTimeCodeValue: Number = 0;
  public selectedTriggerTypeValue: Number = 0;
  public triggerType: Triggertype[] = [];
  public timeCode: any;
  public timeCodeData: Timecode[] = [];
  @Input() clientConfigurationId: number = 1;
  public selectedTriggerType: string | null;
  filteredTimeCodeMasterData: any = [];
  public trigtype: any = {};
  public triggerTypeData: Triggertype[] = [];
  @Input() timeCodeMasterData: any = [];
  timeCodeIds: any = [];
  timeCodes: any = [];
  // stringifiedObj: any;

  get getFormControl() { return this.ondemandForm.controls; }

  public ondemandForm: FormGroup;
  //  ValidationErrors = {
  //   [key: string]: any;}
  public pageSizes: boolean = true;

  public isRangeofClaims = false;
  public result;
  public fileExcelIcon: SVGIcon = fileExcelIcon;
  public filePdfIcon: SVGIcon = filePdfIcon;
  constructor(@Inject(ClientService) private clientService: ClientService, @Inject(JobSetupService) private jobSetupService: JobSetupService, @Inject(MasterdataService) private masterdataService: MasterdataService, @Inject(TriggerEventService) private triggereventService: TriggerEventService, @Inject(OndemandprocessingService) private ondemandprocessingService: OndemandprocessingService, private fb: FormBuilder, private dialogService: DialogService, private _location: Location, @Inject(DOCUMENT) public document: Document, public auth: AuthService) {
    this.masterdataService.allclientforOnDemandJob().subscribe(data => {
      this.clientData = data.filter(s => s.isActive == true);
      this.client = data.filter(s => s.isActive == true);
    });
    // this.clientService.getAllclientconfiguration().subscribe(data => {
    //   // this.selectedClientId = data;
    //   this.clientData = data.filter(s => s.isActive == true);
    //   let clientObj = '';
    //   // event.forEach(element => {
    //   //   orgIdArr.push(element.id);
    //   // });

    //   for (let i = 0; i < data.length; i++) {
    //     // let clientObj = { id: data.clientId, name: data.clientName }

    //     clientObj = data[i].clientName
    //     // clientObj.push(data[i].clientName)
    //   }
    //   // this.client = clientObj
    //   // this.clientGridView = this.clientData;
    //   // console.log(this.clientService.getAllclientconfiguration())
    //   const stringifiedObj = JSON.stringify(data)
    //   console.log(this.client)

    //   // localStorage.setItem('stringifiedObj',stringifiedObj);
    // });

    this.masterdataService.getAllTriggerType().subscribe(data => {
      this.triggerTypeData = data;
      this.triggerType = data;
    });
    this.triggereventService.getClientTriggerEventConfigurationDetails(this.clientConfigurationId).subscribe(data => {
      this.timeCode = data;
      // this.timeCodeData = data.timeCodeId;
    });
    this.jobSetupService.getClientJobsDetails(this.clientId).subscribe(data => {
      this.jobNameSource = data;
      this.jobName = data;
    });
    this.ondemandForm = this.fb.group({
      clientName: new FormControl(this.ondemand.clientName, [Validators.required]),
      jobName: new FormControl(this.ondemand.jobName, [Validators.required]),
      isRangeOfClaims: new FormControl(false),
      triggerJobId: new FormControl(),
      clientId: new FormControl(),
      startClaimId: new FormControl(this.ondemand.startClaimId, [Validators.pattern("^[0-9]+$")]),
      endClaimId: new FormControl(this.ondemand.endClaimId, [Validators.pattern("^[0-9]+$")]),
      trigerredOn: new FormControl(),
      triggeredBy: new FormControl(),
      isNotificationRequiredForUnprocessedClaim: new FormControl(false),
      triggerType: new FormControl(this.ondemand.triggerType, [Validators.required]),
      timeCodeToBeTracked: new FormControl(),
    })

  }
  public jobNameFilter(value: string) {
    this.jobNameSource = this.jobName;
    this.jobNameSource = this.jobName.filter(
      (s) => s.jobName.toLowerCase().indexOf(value?.toLowerCase()) !== -1
    );

  }
  public clientNameSelectionChange(value: any): void {
    this.ondemandForm.patchValue({ jobName: [] });
    this.jobSetupService.getClientJobsDetails(value.id).subscribe(data => {
      this.jobNameSource = data.filter(s => s.isActive == true);
      this.jobName = data.filter(s => s.isActive == true);
    });

  }

  public jobNameSelectionChange(value: any): void {

    this.selectedJobName = value.jobName;
    this.triggereventService.getClientTriggerEventConfigurationDetails(value.clientConfigurationId).subscribe(data => {


      let triggerTypeObj = { id: data.triggerTypeId, name: data.triggerType }
      // this.ondemandForm.controls['triggerType'].setValue(triggerTypeObj)
      // this.ondemandForm.controls['triggerType'].disable()
      // this.selectedTriggerType = data.triggerType

      // triggerTypeObj = data.triggerType
      // this.timeCodeIds = this.timeCodeMasterData?.filter(item => String(data.timeCodeId)?.split(',').includes(item.id.toString()));
      // this.timeCodes = this.timeCodeMasterData?.filter(item => String(data.timeCode)?.split(',').includes(item.id.toString()));


    });
    this.triggereventService.getClientJobConfigurationDetails(value.clientConfigurationId, value.jobId).subscribe(data => {
      console.log(data);
      let triggerTypeObj = { id: data.triggerTypeId, name: data.triggerType }
      // this.ondemandForm.controls['triggerType'].setValue(triggerTypeObj)
      this.triggerTypeData = this.triggerType?.filter(item => String(data.triggerTypeId)?.split(',').includes(item.id.toString()));
      this.ondemandForm.controls['triggerType'].reset()
      this.ondemandForm.controls['timeCodeToBeTracked'].reset()


      // this.selectedTriggerType = data.triggerType

      // triggerTypeObj = data.triggerType
      this.timeCodeIds = this.timeCodeMasterData?.filter(item => String(data.timeCodeId)?.split(',').includes(item.id.toString()));

      // this.timeCode = this.timeCodeMasterData?.filter(item => String(data.timeCode)?.split(',').includes(item.timeCode));
      // this.timeCodeIds = this.timeCode.replace(/,\s*$/, "");
      // this.timeCodes = this.timeCodeMasterData?.filter(item => String(data.timeCode)?.split(',').includes(item.id.toString()));

      console.log(this.timeCode)
    });
  }
  private onTriggerChange(value): void {
    // this.ondemandForm.get('timeCodeToBeTracked').subscribe(value => {
    // const timeCodeControl = this.ondemandForm.get('timeCodeTo');
    const validators = [Validators.required];

    if (value.type === 'Docket') {
      // timeCodeControl.addValidators(validators);
      this.ondemandForm.get('timeCodeToBeTracked').setValidators([Validators.required])
    } else {
      // timeCodeControl.removeValidators([Validators.required]);
      this.ondemandForm.get('timeCodeToBeTracked').removeValidators([Validators.required])

    }

    this.ondemandForm.get('timeCodeToBeTracked').updateValueAndValidity();
    // });
  }

  public triggerTypeFilter(value: string) {
    this.triggerTypeData = this.triggerType;
    this.triggerTypeData = this.triggerType.filter(
      (s) => s.type.toLowerCase().indexOf(value?.toLowerCase()) !== -1
    );
  }

  public triggerTypeSelectionChange(value: any): void {
    this.selectedTriggerType = value.type;
    this.onTriggerChange(value);
    localStorage.setItem('triggerType', this.selectedTriggerType);

  }

  public timeCodeFilter(value: string) {
    //this.timeCodeData = this.timeCode;
    this.filteredTimeCodeMasterData = this.timeCodeMasterData.filter(
      (s) => s.code.toLowerCase().indexOf(value?.toLowerCase()) !== -1
    );
  }


  public clientFilter(value: string) {
    this.clientData = this.client;
    this.clientData = this.client.filter(
      (s) => s.name.toLowerCase().indexOf(value?.toLowerCase()) !== -1
    );
  }

  ngOnInit(): void {
    this.LoggedInName = sessionStorage.getItem('username');
    this.getOnDemandData(this.clientId);
    // this.onTriggerChange();
    this.masterdataService.getAllTimecode().subscribe(data => {
      this.timeCodeMasterData = data.filter(s => s.isActive == true);
    });
    if (sessionStorage.getItem('token') == null) {
      this.auth.logout({
        logoutParams: {
          returnTo: this.document.location.origin
        }
      });
    }
  }

  public addOnDemandProcessing(): void {
    this.addDemand = true;
    this.clientId = 0;
  }

  public getFormData(): any {
    // if(this.LoggedInName == null || this.LoggedInName == '' || this.LoggedInName == undefined){
    //   this.LoggedInName = 'pranay';
    // }
    let data: OnDemandProcessingDetails = new OnDemandProcessingDetails();
    const formValue = this.ondemandForm.getRawValue();
    data.clientId = formValue.clientName.id;
    data.clientName = formValue.clientName.name;
    data.jobName = formValue.jobName.jobName;
    data.triggerJobId = formValue.jobName.jobId;
    data.isRangeOfClaims = formValue.isRangeOfClaims;
    data.triggerTypeId = formValue.triggerType.id;
    if (data.triggerTypeId == 1) {
      data.timeCodeIds = formValue.timeCodeToBeTracked ? formValue.timeCodeToBeTracked.filter(s => s.isActive).map(itm => itm.id).sort().join(',') : [];
      data.timeCodes = formValue.timeCodeToBeTracked ? formValue.timeCodeToBeTracked.filter(s => s.isActive).map(itm => itm.code).sort().join(',') : [];

    }
    if (this.isRangeofClaims == true) {
      data.startClaimId = formValue.startClaimId;
      data.endClaimId = formValue.endClaimId;
    }
    else {
      data.startClaimId = formValue.startClaimId;
      // data.endClaimId = null;
    }
    data.triggeredBy = this.LoggedInName;
    // data.isStoppedProcessing = formValue.isStoppedProcessing;
    data.trigerredOn = new Date().toISOString();
    data.isNotificationRequiredForUnprocessedClaim = formValue.isNotificationRequiredForUnprocessedClaim;
    data.createdBy = this.LoggedInName;
    // data.createdDate = '05/25/2023';
    return data;
  }
  onCheckboxChange(e) {

    if (e.target.checked) {
      this.ondemandForm.controls['endClaimId'].setValue(null);

    } else {
      this.ondemandForm.controls['endClaimId'].setValue(Number.MAX_SAFE_INTEGER);
    }
  }
  // public enabledisable(dataItem): any{
  //   if(dataItem.isStoppedProcessing = true){
  //     dataItem.isStoppedProcessing = false;


  //   }

  // }

  public onClick(data): any {
    if (data.status == 'NULL') {
      data.isStoppedProcessing = true;
    }
    else if (data.status == 'Pending') {
      // data.OndemandClaimProcessingId = data.id;
      data.isStoppedProcessing = true;
    }
    else if (data.status == 'InProgress') {
      data.isStoppedProcessing = true;
    }
    data.modifiedBy = this.LoggedInName;
    this.StopOnDemandProcessedClaimsDetails(data);
    // return data;
  }



  public saveondemand(): void {

    let data = this.getFormData();
    if (data.isRangeOfClaims && data.startClaimId >= data.endClaimId) {
      this.showConfirmation("Error", "Start Claim ID should be less than End Claim ID")
    }
    else {

      this.postOnDemandProcessedClaimsDetails(data);
    }
    this.ondemandForm.markAsPristine();
  }

  public exit() {
    if (this.ondemandForm.dirty) {
      this.showConfirmationOnBack("You have unsaved data on the page.Do you want to continue?");
    }
    else {
      window.location.reload();
      // this.getOnDemandData(this.clientId);
    }
  }


  public postOnDemandProcessedClaimsDetails(data: any) {
    this.ondemandprocessingService.postOnDemandProcessedClaimsDetails(data).subscribe((resp) => {
      this.clientId = resp.id;
      if (resp.isSuccess) {
        this.showConfirmation("Success", "On Demand Job Processing added successfully");
        console.log("On Demand processing added successfully");

        this.getOnDemandData(this.clientId);
      } else {
        this.showConfirmation("Error", "Error : " + resp.message);
        console.log("Error: " + resp.message);
      }
    }, (error: any) => {
      console.log(error.message);

    });
  }
  public putOnDemandProcessedClaimsDetails(data: any) {
    this.ondemandprocessingService.putOnDemandProcessedClaimsDetails(data).subscribe((resp) => {
      this.clientId = resp.id;
      if (resp.isSuccess) {
        this.showConfirmation("Success", "On Demand Job Processing updated successfully");
        console.log("On Demand processing updated successfully");

        // this.getOnDemandData(this.clientId);
      } else {
        this.showConfirmation("Error", "Error : " + resp.message);
        console.log("Error: " + resp.message);
      }
    }, (error: any) => {
      console.log(error.message);

    });
  }

  public StopOnDemandProcessedClaimsDetails(data: any) {
    this.ondemandprocessingService.StopOnDemandProcessedClaimsDetails(data).subscribe((resp) => {
      // data.modifiedBy = resp.modifiedBy;
      if (resp.isSuccess) {
        this.showConfirmation("Success", "On Demand Job Processing stopped successfully");
        console.log("On Demand processing updated successfully");

        this.getOnDemandData(this.clientId);
      } else {
        this.showConfirmation("Error", "Error : " + resp.message);
        console.log("Error: " + resp.message);
      }
    }, (error: any) => {
      console.log(error.message);

    });
  }
  public getOnDemandData(clientId: number) {
    this.ondemandprocessingService.GetOnDemandProcessedClaimsDetailsByClientId(clientId).subscribe(data => {
      this.ondemandApiData = data.reverse();
      console.log(this.onDemandGridView)
      this.onDemandGridView = this.ondemandApiData;

      if (this.ondemandApiData.length == 1) {
        this.ondemandForm.patchValue({
          clientId: this.client.find(item => item.id === this.ondemandApiData[0].clientId),

        },);
      }
      else if (this.ondemandApiData.length > 1) {
        this.onDemandGridView = this.ondemandApiData;
      }
    });
  }



  public showConfirmation(dialogTitle, dialogContent): void {
    const dialog: DialogRef = this.dialogService.open({
      title: dialogTitle,
      content: dialogContent,
      actions: [{ text: "Close", themeColor: "primary", cssClass: "modal-close-button" }],
      width: 400,
      height: 150,
      minWidth: 250,
    });

    dialog.result.subscribe((result) => {
      if (result instanceof DialogCloseResult) {
        console.log("close");
        window.location.reload();


      } else {
        console.log("action", result);
        this.addDemand = false;
        window.location.reload();

      }
      this.result = JSON.stringify(result);
    });
  }

  public onFilter(input: Event): void {
    const inputValue = (input.target as HTMLInputElement).value;

    this.onDemandGridView = process(this.ondemandApiData, {
      filter: {
        logic: "or",
        filters: [
          {
            field: "clientName",
            operator: "contains",
            value: inputValue,
          },
          {
            field: "clientId",
            operator: "contains",
            value: inputValue,
          },
          {
            field: "triggeredBy",
            operator: "contains",
            value: inputValue,
          },
          {
            field: "startClaimId",
            operator: "contains",
            value: inputValue,
          },
          {
            field: "isRangeOfClaims",
            operator: "eq",
            value: inputValue,
          },
          {
            field: "endClaimId",
            operator: "contains",
            value: inputValue,
          },
          {
            field: "jobName",
            operator: "contains",
            value: inputValue,
          },
          {
            field: "triggerType",
            operator: "contains",
            value: inputValue,
          },
          {
            field: "timeCodes",
            operator: "contains",
            value: inputValue,
          },
          {
            field: "status",
            operator: "contains",
            value: inputValue,
          },
          {
            field: "trigerredOn",
            operator: "contains",
            value: inputValue,
          },
          {
            field: "createdBy",
            operator: "contains",
            value: inputValue,
          },
          {
            field: "createdDate",
            operator: "contains",
            value: inputValue,
          },
          {
            field: "modifiedBy",
            operator: "contains",
            value: inputValue,
          },
          {
            field: "modifiedDate",
            operator: "contains",
            value: inputValue,
          },


        ],
      },
    }).data;
  }
  public openmessage(message) {
    this.Title = "Message";
    this.openedmessage = true;
    this.message = message;
    this.showmessageTextbox();
  }

  public showmessageTextbox() {
    this.ismessageTextboxVisible = true;
  }

  public close(status: string): void {
    this.openedmessage = false;
  }

  public showConfirmationOnBack(dialogContent): void {
    const dialog: DialogRef = this.dialogService.open({
      title: "Please confirm",
      content: dialogContent,
      actions: [{ text: "Yes" }, { text: "No", themeColor: "primary" }],
      width: 350,
      height: 170,
    });

    dialog.result.subscribe((result) => {
      if (result instanceof DialogCloseResult) {
        console.log("close");
      } else {
        if (result.text == "Yes") {
          //this._location.back();
          window.location.reload();
        }
      }
      this.result = JSON.stringify(result);
    });
  }

}
